<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="货主名称">
              <el-input v-model="form.cargoOwnerName" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="货主代码">
              <el-input v-model="form.cargoOwnerCode" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="状态">
              <el-select v-model="form.status" :placeholder="$t('page.selectPlaceholder')" clearable>
                <el-option label="启用" value="0" />
                <el-option label="禁用" value="1" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick(0)">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-button type="primary" icon="el-icon-plus" @click="addOwner()">新建</el-button>
    </el-row>
    <!-- <table-select-action :quantity="multipleSelection.length" @reset="handleTableSelectReset" /> -->
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3 mt-3"
      :data="tableDatas"
      :header-cell-style="{ background: '#fafafa', color: '#606266' }"
      max-height="500px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="50" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="cargoOwnerName" label="货主名称" />
      <el-table-column prop="cargoOwnerCode" label="货主代码" />
      <el-table-column prop="checkBillCode" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0" class="use-status-open">启用</span>
          <span v-else class="use-status-close">禁用</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="addOwner(scope.row)">修改</el-button>
          <!-- <el-button type="text" @click="viewOrder(true)">查看</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        :background="true"
        :current-page="pager.current"
        :page-size="pager.size"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10,20,50,100]"
        :total="pager.total"
        v-bind="$attrs"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 新增修改弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-form ref="addForm" :model="addForm" label-width="100px" :rules="rules">
        <el-form-item label="货主名称" prop="cargoOwnerName">
          <el-input v-model="addForm.cargoOwnerName" />
        </el-form-item>
        <el-form-item label="货主代码" prop="cargoOwnerCode">
          <el-input v-model="addForm.cargoOwnerCode" />
        </el-form-item>
        <el-form-item label="公司" prop="vendorId">
          <el-select v-model="addForm.vendorId" placeholder="请选择" filterable @change="changeCargo">
            <el-option
              v-for="item in vendorList"
              :key="item.id"
              :label="item.vendorFull"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="货主全称" prop="cargoOwnerFullName">
          <el-input v-model="addForm.cargoOwnerFullName" disabled />
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="addForm.address" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="addForm.status">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible=false">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="dialogVisibleSure">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'
// import TableSelectAction from '@/components/TableSelectAction'
import { cargoownerList, addCargoowner, editCargoowner } from '@/api/scm-api'
import { getVendorListByVendorType } from '@/api/blurbInformation'

export default {
  data() {
    return {
      form: {},
      multipleSelection: [],
      tableDatas: [],
      dialogVisible: false,
      addForm: {
        vendorId: '',
        status: 0,
        cargoOwnerFullName: '', address: ''
      },
      TableLoading: false,
      pager: {
        total: 0,
        current: 1,
        size: 10
      },
      submitLoading: false,
      rules: {
        vendorId: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'change' },
        cargoOwnerName: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' },
        cargoOwnerCode: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' },
        cargoOwnerFullName: { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' }
      },
      edit: false,
      row: {},
      vendorList: []
    }
  },
  computed: {
    getVendorType() {
      return Object.assign({}, { vendorType: 5 })
    },
    queryParams() {
      return Object.assign({}, this.form, this.pager)
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (!val) {
        this.addForm = this.$options.data.call(this).addForm
        this.$refs['addForm'].resetFields()
      }
    }
  },
  created() {

  },
  mounted() {
    this.queryClick(0)
    this._getVendorListByVendorType()
  },
  methods: {
    handleSizeChange(val) {
      this.pager.size = val
      this.queryClick(0)
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this.queryClick(1)
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    async _getVendorListByVendorType() {
      const { datas } = await getVendorListByVendorType(this.getVendorType)
      this.vendorList = datas
    },
    changeCargo(val) {
      console.log(val)
      const fullName = this.vendorList.find(e => { return e.id === val })
      this.addForm.cargoOwnerFullName = fullName.vendorFull
    },
    addOwner(row) {
      if (row) {
        this.row = row
        this.edit = true
        const { vendorId, status, cargoOwnerName, cargoOwnerCode, cargoOwnerFullName, address } = row
        this.addForm = { vendorId, status, cargoOwnerName, cargoOwnerCode, cargoOwnerFullName, address }
      }
      this.dialogVisible = true
    },
    async queryClick(type) {
      try {
        type === 0 ? this.pager.current = 1 : ''
        this.TableLoading = true
        const { code, datas: { pager, records }} = await cargoownerList(this.queryParams)
        code === 0 ? this.tableDatas = records : ''
        this.pager = pager
        this.TableLoading = false
      } catch (err) {
        console.log(err)
      } finally {
        this.TableLoading = false
      }
    },
    resetClick() {
      this.form = {}
      this.queryClick(0)
    },
    dialogVisibleSure() {
      this.$refs['addForm'].validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            if (!this.edit) {
              await addCargoowner(this.addForm)
            } else {
              const obj = Object.assign({}, this.addForm, { id: this.row.id })
              await editCargoowner(obj)
            }
            this.submitLoading = false
            this.dialogVisible = false
            this.edit = false
            this.queryClick(0)
          } catch (err) { console.log(err) } finally {
            this.submitLoading = false
          }
        }
      }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.pagination-container {
  background: #fff;
  padding: 0px 16px;
}
</style>
